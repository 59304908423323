class BrandTextTemplateDefault extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('TextTemplateDefault before load');
	}

	afterLoad() {
		// console.log("L'elemento ha la classe health-warning");
	}

	beforeUpdate() {
		// console.log('HeadlineTemplate before update');
	}

	afterUpdate() {
		// console.log('HeadlineTemplate after update');
	}

	unload() {
		// console.log('HeadlineTemplate after unload');
	}
}

!customElements.get('bat-text-default') &&
	customElements.define('bat-text-default', BrandTextTemplateDefault);
